<template>
    <div>
        <!-- 客户百分比通道组-增加或修改 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>{{cur_title}}</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <!-- 客户cpid+公司名 -->
                <el-row type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                    <el-table :data="tableData_title" :show-header="false" style="width: 100%" size="mini" >
                        <el-table-column  prop="label" width="110" align="center"></el-table-column>
                        <el-table-column  prop="show_value" min-width="200" align="right"></el-table-column>
                    </el-table>
                </el-row>

                <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                    <el-form :model="form_data" label-width="140px" style="margin-bottom: 12px;">

                        <el-form-item label="通道">
                            <el-select v-model="form_data.user_channel_id" filterable size="mini" style="width:100%" 
                                placeholder="请选择通道" v-show="this.prop_type == 'add'"> 
                                <el-option
                                    v-for="item in channelList"
                                    :key="item.id"
                                    :label="item.channel_name+'('+item.id+')'"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <span v-show="this.prop_type == 'upd'">
                                {{user_channel_name}}
                            </span>
                        </el-form-item>

                        <el-form-item label="百分比">
                            <el-input v-model="form_data.rate" placeholder="请填写百分比" size="mini" oninput="value=value.replace(/[^\d.]/g,'')">
                            </el-input>
                        </el-form-item>
                    
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-form-item>
                    </el-form>

                </el-row>

            </el-row>

        </el-dialog>
    </div>
</template>
<script>
import API from '../../api/api';

export default {
    props:{
        prop_change_i:0,
        prop_type:'',//类型 add:增加  upd:修改
        prop_item:{},//公司对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();

            this.form_data.cpid = this.prop_item.cpid;//对应t_user表的cpid
            var table_obj = {label:"客户账号",show_value:this.prop_item.cpid,value:this.prop_item.cpid,name:"cpid"};
            this.tableData_title.push(table_obj);

            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                if(this.prop_type === "upd"){//修改
                    this.cur_title = "客户百分比通道的修改";
                    setTimeout(() => {
                        //获取数据
                        this.getData();

                        //请求接口-获取通道列表数据-指定通道组使用的
                        this.getChanenlList();
                    }, 1);
                }else{
                    this.cur_title = "客户百分比通道的增加";
                    setTimeout(() => {
                        //请求接口-获取通道列表数据-指定通道组使用的
                        this.getChanenlList();
                    }, 1);
                }
            }
        }
    },

    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头

            tableData_title:[],//客户cpid+公司名
            
            channelList:[],//通道列表数据-指定通道组使用的--请求接口获取

            user_channel_name: '',

            form_data:{
                cpid:"",//对应t_user表的cpid
                user_channel_id:"",//通道id
                rate:1,//百分比 1 - 100整数
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);  
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
            this.tableData_title = this.$options.data().tableData_title;//单个恢复初始化
       
        },
        //查询
        getData(){
            this.form_data.user_channel_id = this.prop_item.channel_id;//通道
            this.form_data.rate = this.prop_item.rate;//百分比
            this.user_channel_name = this.prop_item.channel_name+"("+this.prop_item.channel_id+")";
        },
        //获取通道列表数据-指定通道组使用的
        getChanenlList(){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:"",//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                }
            });
        },

        //提交
        onSubmit(){
            console.log("提交");
            if(API.isEmtry(this.form_data.user_channel_id)){
                this.$message.error("请选择通道");
                return;
            }
            if(API.isEmtry(this.form_data.rate)){
                this.$message.error("请填写百分比");
                return;
            }

            var submit_obj = {};
            if(this.prop_type === "upd"){//修改
                submit_obj.param = "percentChannelGroupUpd";
            }else{//增加
                submit_obj.param = "percentChannelGroupAdd";
            }
            submit_obj.cpid = this.form_data.cpid;//对应t_user表的cpid
            submit_obj.channel_id = this.form_data.user_channel_id;//通道id
            submit_obj.rate = this.form_data.rate;//百分比 1 - 100整数

            API.UserServlet(submit_obj).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },
    }
};
</script>
<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}

.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>