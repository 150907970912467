<template>
<div>
    <!-- 客户价格修改 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>客户价格修改</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-form  :model="form_data" label-width="120px" style="margin-bottom: 12px;">
                    <el-form-item label="开始日期">
                        <el-date-picker
                            v-model="form_data.start_time"
                            type="date"
                            placeholder="选择月" size="mini" style="width:150px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="结束日期">
                        <el-date-picker
                            v-model="form_data.end_time"
                            type="date"
                            placeholder="选择月" size="mini" style="width:150px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="移动售价(分/条)">
                        <el-input v-model="form_data.CM_price" placeholder="请输入移动售价,单位分/条" size="mini" style="width:70px;margin-right:20px;"
                            oninput="value=value.replace(/[^\d+(\.\d+)?$]/g,'')">
                        </el-input>

                        <span>返佣</span>
                        <el-input v-model="form_data.CM_rebate" placeholder="请输入移动返佣,单位分/条" size="mini" style="width:70px;margin-left:10px;"
                            oninput="value=value.replace(/[^\d+(\.\d+)?$]/g,'')">
                        </el-input>

                    </el-form-item>

                   <el-form-item label="联通售价(分/条)">
                        <el-input v-model="form_data.CU_price" placeholder="请输入联通售价,单位分/条" size="mini" style="width:70px;margin-right:20px;"
                            oninput="value=value.replace(/[^\d+(\.\d+)?$]/g,'')">
                        </el-input>

                        <span>返佣</span>
                        <el-input v-model="form_data.CU_rebate" placeholder="请输入联通返佣,单位分/条" size="mini" style="width:70px;margin-left:10px;"
                            oninput="value=value.replace(/[^\d+(\.\d+)?$]/g,'')">
                        </el-input>

                    </el-form-item>

                    <el-form-item label="电信售价(分/条)">
                        <el-input v-model="form_data.CT_price" placeholder="请输入电信售价,单位分/条" size="mini" style="width:70px;margin-right:20px;"
                            oninput="value=value.replace(/[^\d+(\.\d+)?$]/g,'')">
                        </el-input>

                        <span>返佣</span>
                        <el-input v-model="form_data.CT_rebate" placeholder="请输入电信返佣,单位分/条" size="mini" style="width:70px;margin-left:10px;"
                            oninput="value=value.replace(/[^\d+(\.\d+)?$]/g,'')">
                        </el-input>

                    </el-form-item>
                   
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
                                    
                


            </el-row>
            

           
            

        </el-row>


    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_item:{//对象
            cpid:"",
            CM_price:"0",//移动价格 单位分
            CU_price:"0",//联通价格 单位分
            CT_price:"0",//电信价格 单位分
            CM_rebate:"0",//移动返佣 单位分
            CU_rebate:"0",//联通返佣 单位分
            CT_rebate:"0",//电信返佣 单位分
        },
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{

                this.dialog_visible = true;//弹出框是否可见 
                //初始化设置
                this.initSetUp();
                

                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度


            form_data:{
                start_time:new Date((new Date().getTime() + 24*60*60*1000)),//开始日期
                end_time:new Date((new Date().getTime() + 24*60*60*1000)),//结束日期
                CM_price:"",//移动价格 单位分
                CU_price:"",//联通价格 单位分
                CT_price:"",//电信价格 单位分
                CM_rebate:"",//移动返佣 单位分
                CU_rebate:"",//联通返佣 单位分
                CT_rebate:"",//电信返佣 单位分
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 180);
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
        //查询
        getData(){
            this.form_data.CM_price = this.prop_item.CM_price;////移动价格 单位分
            this.form_data.CU_price = this.prop_item.CU_price;//
            this.form_data.CT_price = this.prop_item.CT_price;//
            this.form_data.CM_rebate = this.prop_item.CM_rebate;////移动返佣 单位分
            this.form_data.CU_rebate = this.prop_item.CU_rebate;//
            this.form_data.CT_rebate = this.prop_item.CT_rebate;//
        },
        


        //提交
        onSubmit(){
            console.log("提交");
            

            // console.log("this.form_data.blanceCallNum:"+this.form_data.blanceCallNum);
            // console.log("submit_obj.blanceCallNum:"+submit_obj.blanceCallNum);

            if(API.isEmtry(this.form_data.start_time)){
                this.$message.error("请填写开始日期");
                return;
            }
            if(API.isEmtry(this.form_data.end_time)){
                this.$message.error("请填写结束日期");
                return;
            }
            if(API.isEmtry(this.form_data.CM_price)){
                this.$message.error("请填写移动售价");
                return;
            }
            if(API.isEmtry(this.form_data.CM_rebate)){
                this.$message.error("请填写移动返佣");
                return;
            }
            if(API.isEmtry(this.form_data.CU_price)){
                this.$message.error("请填写联通售价");
                return;
            }
            if(API.isEmtry(this.form_data.CU_rebate)){
                this.$message.error("请填写联通返佣");
                return;
            }
            if(API.isEmtry(this.form_data.CT_price)){
                this.$message.error("请填写电信售价");
                return;
            }
            if(API.isEmtry(this.form_data.CT_rebate)){
                this.$message.error("请填写电信返佣");
                return;
            }
            
            
            var submit_obj = {};
            submit_obj.cpid = this.prop_item.cpid;
            submit_obj.param="userPriceUpd";
            submit_obj.CM_price = this.form_data.CM_price;
            submit_obj.CU_price = this.form_data.CU_price;
            submit_obj.CT_price = this.form_data.CT_price;
            submit_obj.CM_rebate = this.form_data.CM_rebate;
            submit_obj.CU_rebate = this.form_data.CU_rebate;
            submit_obj.CT_rebate = this.form_data.CT_rebate;
            submit_obj.start_time = dateFtt(this.form_data.start_time,"yyyyMMdd");
            submit_obj.end_time = dateFtt(this.form_data.end_time,"yyyyMMdd");
            
            API.UserPriceServlet(submit_obj).then((res) => {
                
                if (res.recode === 0) {
                    this.$message.success("恭喜您，价格修改成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>