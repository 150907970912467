<template>
<div id="channel_price_upd_log">
    <!-- 客户详情-弹出层 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>客户分流账号详情</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
               
                
                <!-- 高级属性 -->
                <el-table  :data="tableData_high" :show-header="false"  style="width: 100%"  size="mini" >
                    <el-table-column  fixed  prop="label"  width="130"  align="center"> </el-table-column>
                    <el-table-column  min-width="200" align="right">
                        <template slot-scope="scope">
                            <template v-if="scope.row.name == 'channel_select_type' && detail_obj.channel_select_type == 0">
                                <span>{{scope.row.show_value}}</span>
                                <el-button type="text" size="mini" @click="tableData_channel_group_isshow = !tableData_channel_group_isshow"  style="margin-left:10px;">{{tableData_channel_group_isshow ? '折叠' : '展示'}}</el-button>
                            </template>
                            <template v-else>
                                {{scope.row.show_value}}
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 指定通道组列表 -->
                <el-table v-show="tableData_channel_group_isshow" :data="tableData_channel_group" :show-header="false"  style="width: 100%"  size="mini" >
                    <el-table-column  prop="channel_name"  min-width="110"  align="center"> </el-table-column>
                    <el-table-column  width="100" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini"  @click="channel_detail(scope.row.channel_id)">通道详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-row type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                     <el-button type="primary" size="mini" style="padding:10px 50px;" @click="upd()">修改</el-button>
                </el-row>
                                    
            </el-row>
            
        </el-row>


    </el-dialog>

    <!-- 通道详情-弹出层组件 -->
    <ChannelDetailSelect :prop_change_i="prop_channel_detail_i" :prop_channel_id="prop_channel_detail_id"></ChannelDetailSelect>

    <!-- 客户分流属性分流账号-增加或修改组件 -->
    <UserShuntAddUpd :prop_change_i="prop_shunt_add_upd_i"
        :prop_item="detail_obj"
        :prop_type="prop_shunt_add_upd_type"
        @result="userShuntAddUpdResult"
    ></UserShuntAddUpd>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';
import ChannelDetailSelect from '../channel/channel_detail_select.vue';//组件 通道详情-弹出层
import UserShuntAddUpd from './user_shunt_add_upd.vue';//客户分流属性分流账号-增加或修改组件

//import axios from "axios";
export default {
     components: {
       ChannelDetailSelect,
       UserShuntAddUpd
    },
    props:{
        prop_change_i:0,
        prop_item:{cpid:"",cpid_shunt:""}
    },
    watch:{//监听props
        prop_change_i(val){
             //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
            
                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度
            
            detail_obj : {},//客户详情的对象  通过请求接口获取

            tableData_high:[],//客户高级属性数据
            tableData_channel_group:[],//客户指定通道组
            tableData_channel_group_isshow:true,//客户指定通道组是否展示  true:展示

            //通道详情
            prop_channel_detail_i:0,//弹出框是否可见  通道详情
            prop_channel_detail_id:"",//通道id

             //客户分流属性分流账号-增加或修改
            prop_shunt_add_upd_i:0,//弹出框是否可见  客户分流属性分流账号-增加或修改
            prop_shunt_add_upd_type:'upd',//客户分流属性分流账号-增加或修改  组件 类型  add：增加  upd:修改


           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
        },
         //数据清除
        dataClear(){

            this.detail_obj = this.$options.data().detail_obj;//单个恢复初始化
            this.tableData_high = this.$options.data().tableData_high;//单个恢复初始化
            this.tableData_channel_group = this.$options.data().tableData_channel_group;//单个恢复初始化
            this.tableData_channel_group_isshow = this.$options.data().tableData_channel_group_isshow;//单个恢复初始化
            this.prop_channel_detail_i = this.$options.data().prop_channel_detail_i;//单个恢复初始化
            this.prop_shunt_add_upd_i = this.$options.data().prop_shunt_add_upd_i;//单个恢复初始化

        },
        //修改
        upd(){
            console.log("客户分流属性分流账号-修改");
            this.prop_shunt_add_upd_i ++;//弹出框是否可见
        },
        //客户分流属性分流账号-结果
        userShuntAddUpdResult(){
            this.getData();
        },

         //通道详情
        channel_detail(channel_id){
            console.log("通道详情"+channel_id);
            this.prop_channel_detail_id = channel_id;//通道id
            this.prop_channel_detail_i ++;//弹出框是否可见
        },

        //查询
        getData(){
           
            this.tableData_high = this.$options.data().tableData_high;//单个恢复初始化
            this.tableData_channel_group = this.$options.data().tableData_channel_group;//单个恢复初始化

            var cpid = this.prop_item.cpid;//客户id
            var cpid_shunt = this.prop_item.cpid_shunt;
           //请求接口
            API.UserShuntServlet({
                param: "detail",
                cpid:cpid,
                cpid_shunt:cpid_shunt
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    this.detail_obj = res.detail;
                    this.tableData_channel_group =  res.detail.user_channel_groups;////通道选取方式为指定通道组时，指定的通道列表
                    var show_value = "";
                    if(this.tableData_channel_group.length > 0){
                        this.tableData_channel_group_isshow = true;
                    }else{
                        this.tableData_channel_group_isshow = false;
                    }
                    
                    
                    //------高级-----------------------------------------------------------------------------------------------
                    if(2 == 2){
                        var table_obj = {label:"主账号",show_value:this.prop_item.cpid,value:this.prop_item.cpid,name:"cpid"};
                        this.tableData_high.push(table_obj);
                        
                        table_obj = {label:"分流账号",show_value:this.prop_item.cpid_shunt,value:this.prop_item.cpid_shunt,name:"cpid_shunt"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"要求扩展码位数",show_value:res.detail.ext_num == 0 ? '不要求' : res.detail.ext_num+'位',value:res.detail.ext_num,name:"ext_num"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"上行是否要求",show_value:res.detail.mo_onoff == 0 ? '要求' : '不要求',value:res.detail.mo_onoff,name:"mo_onoff"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"特殊分类标签",show_value:res.detail.special_label == '' ? '无特殊标签' : res.detail.special_label,value:res.detail.special_label,name:"special_label"};
                        this.tableData_high.push(table_obj);

                        

                        table_obj = {label:"单条最低利润",show_value:res.detail.profit_min+"分",value:res.detail.profit_min,name:"profit_min"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"是否要求指定码号",show_value:res.detail.specify_codenum_onff == 0 ? '要求' : '不要求',value:res.detail.specify_codenum_onff,name:"specify_codenum_onff"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.specify_codenum_onff == 0 ){
                            table_obj = {label:"指定码号",show_value:res.detail.specify_codenum,value:res.detail.specify_codenum,name:"specify_codenum"};
                            this.tableData_high.push(table_obj);
                        }

                        
                        
                        show_value = "";
                        var channel_select_style = res.detail.channel_select_style;//可选通道类别，0:直连；1:准直连；2:三方；3:厂商，复选框(多个使用半角逗号隔开)
                        var channel_select_style_array = channel_select_style.split(",");
                        for(var i =0;i<channel_select_style_array.length;i++){
                            var one = channel_select_style_array[i];
                            if(show_value != ''){
                                show_value += ",";
                            }
                            if(one == '0'){
                                show_value += "直连";
                            }else if(one == '1'){
                                show_value += "准直连";
                            }else if(one == '2'){
                                show_value += "三方";
                            }else if(one == '3'){
                                show_value += "厂商";
                            }
                        }

                        table_obj = {label:"可选通道类型",show_value:show_value,value:res.detail.channel_select_style,name:"channel_select_style"};
                        this.tableData_high.push(table_obj);


                        table_obj = {label:"通道选取方式",show_value:res.detail.channel_select_type == 0 ? '指定通道组' : '自动选通道',value:res.detail.send_type,name:"channel_select_type"};
                        this.tableData_high.push(table_obj);


                    }
           
                }
                
            });
        },

        
        

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

</style>