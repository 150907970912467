<template>
<div>
    <!-- 客户修改-基本信息 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{cur_title}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <!-- 客户cpid+公司名 -->
            <el-row type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                <el-table :data="tableData_titil" :show-header="false"  style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="label"  width="110"  align="center"> </el-table-column>
                    <el-table-column  prop="show_value"  min-width="200" align="right"> </el-table-column>
                </el-table>
            </el-row>

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-form  :model="form_data" label-width="120px" style="margin-bottom: 12px;">
                    <el-form-item label="登录密码">
                        <el-input v-model="form_data.login_pwd" placeholder="请输入登录密码" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="接口密码">
                        <el-input v-model="form_data.cppwd" placeholder="请输入接口密码" size="mini"></el-input>
                    </el-form-item>

                    
                    <el-form-item label="秘钥">
                        <el-input v-model="form_data.secret" placeholder="最长支持64位" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="联系方式">
                        <el-input v-model="form_data.relationphone" placeholder="选填,多个使用半角逗号隔开" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="付费类型">
                        <el-radio-group v-model="form_data.user_type" size="mini"  :disabled="this.prop_item.user_type == 1 ? true : false">
                            <el-radio :label="0">预付费</el-radio>
                            <el-radio :label="1">后付费</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="失败是否返还">
                        <el-radio-group v-model="form_data.fail_rtid" size="mini" >
                            <el-radio label="no">不返还</el-radio>
                            <el-radio label="yes">返还</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="客户鉴权IP">
                        <el-input v-model="form_data.ip" placeholder="多个使用半角逗号隔开" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="客户接入方式">
                        <el-radio-group v-model="form_data.interface_type" size="mini" >
                            <el-radio :label="0">CMPP</el-radio>
                            <el-radio :label="1">HTTP</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="http对接协议" v-show="form_data.interface_type == 1">
                        <el-select v-model="form_data.qxt_http_type" filterable clearable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in httpTypeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="回调地址" v-show="form_data.interface_type == 1">
                        <el-input v-model="form_data.url" placeholder="请填写回调地址" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="上行地址" v-show="form_data.interface_type == 1">
                        <el-input v-model="form_data.mourl" placeholder="请填写上行地址" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="余额提醒" v-show="form_data.user_type == 0">
                        <el-radio-group v-model="form_data.blanceCallNum_onoff" size="mini" @change="form_data.blanceCallNum=''">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="余额提醒阈值" v-show="form_data.blanceCallNum_onoff == 1 && form_data.user_type == 0">
                        <el-input v-model="form_data.blanceCallNum" placeholder="请填写余额提醒阈值" size="mini" oninput="value=value.replace(/[^\d.]/g,'')">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="余额提醒号码" v-show="form_data.blanceCallNum_onoff == 1 && form_data.user_type == 0">
                        <el-input v-model="form_data.phones" placeholder="多个使用半角逗号隔开" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="备注">
                        <el-input v-model="form_data.remark" placeholder="" size="mini"></el-input>
                    </el-form-item>
                   
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
                                    
                


            </el-row>
            

           
            

        </el-row>


    </el-dialog>
</div>
</template>
<script>
import API from '../../api/api';

export default {
    props:{
        prop_change_i:0,
        prop_item:{},//客户对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                //获取 http对接类别 列表数据
                this.getHttpTypeDataList();

                this.cur_title = "客户基本信息修改";
                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头

            tableData_titil:[],//客户cpid+公司名

            httpTypeList:[],//http对接类别 列表数据  -请求接口获取

            form_data:{
                cppwd:"",//用户接口密码
                login_pwd:"",//用户登录密码
                secret:"",//私钥
                relationphone:"",//联系人手机号码，多个用半角逗号隔开--核实 （注：在客户端用于下发短信的验证码使用）
                user_type:"",//用户付费类型0:预付费类型1：后付费类型
                fail_rtid:"",//失败状态返回到的cpid,空不返还
                ip:"",//客户鉴权ip地址(多个用半角逗号隔开)
                interface_type:"",//接入方式，0:CMPP；1:HTTP
                qxt_http_type:"system",//http对接类别表id,空表示没有,只有接入方式(interface_type)是http时显示 例如：zhe_jiang_cai_niao
                url:"",//回调地址
                mourl:"",//上行地址（即上行是回调用）
                blanceCallNum_onoff:0,//余额提醒开关，0：关闭；1：打开
                blanceCallNum:-1,//余额提醒  -1:不提醒，大于0则按照数目提醒  一级账号提醒的单位是分，二级和三级账号是条数
                phones:"",//余额提醒目标号码，多个用半角逗号隔开
                remark:"",//备注 ，在用户统计里显示
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 180);
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
            this.tableData_titil = this.$options.data().tableData_titil;//单个恢复初始化
        },
        //查询
        getData(){
            this.form_data.cppwd = this.prop_item.cppwd;//用户接口密码
            this.form_data.login_pwd = this.prop_item.login_pwd;//用户登录密码
            this.form_data.secret = this.prop_item.secret;//秘钥
            this.form_data.relationphone = this.prop_item.relationphone;//联系人手机号码，多个用半角逗号隔开--核实 （注：在客户端用于下发短信的验证码使用）
            this.form_data.user_type = this.prop_item.user_type;//用户付费类型0:预付费类型1：后付费类型
            this.form_data.fail_rtid = this.prop_item.fail_rtid;//失败状态返回到的cpid,空不返还
            this.form_data.ip = this.prop_item.ip;//客户鉴权ip地址(多个用半角逗号隔开)
            this.form_data.interface_type = this.prop_item.interface_type;//接入方式，0:CMPP；1:HTTP
            this.form_data.qxt_http_type = this.prop_item.qxt_http_type;//http对接类别表id,空表示没有,只有接入方式(interface_type)是http时显示 例如：zhe_jiang_cai_niao
            this.form_data.url = this.prop_item.url;//回调地址
            this.form_data.mourl = this.prop_item.mourl;//上行地址（即上行是回调用）
            this.form_data.blanceCallNum = this.prop_item.blanceCallNum;//余额提醒  -1:不提醒，大于0则按照数目提醒  一级账号提醒的单位是分，二级和三级账号是条数
            this.form_data.phones = this.prop_item.phones;//余额提醒目标号码，多个用半角逗号隔开
            
            this.form_data.remark = this.prop_item.remark;//备注 ，在用户统计里显示

            if(this.form_data.user_type == 1){//后付费
                this.form_data.blanceCallNum = -1;
                this.form_data.phones = "";
                this.form_data.blanceCallNum_onoff = 0;//余额提醒开关，0：关闭；1：打开
            }else{
                if(this.form_data.blanceCallNum == -1){
                    this.form_data.blanceCallNum_onoff = 0;//余额提醒开关，0：关闭；1：打开
                }else{
                    this.form_data.blanceCallNum_onoff = 1;//余额提醒开关，0：关闭；1：打开
                }
            }

            if(this.form_data.fail_rtid == ""){//失败状态返回到的cpid,空不返还
                this.form_data.fail_rtid = "no";
            }else{
                this.form_data.fail_rtid = "yes";
            }

            var table_obj = {label:"客户账号",show_value:this.prop_item.cpid,value:this.prop_item.cpid,name:"cpid"};
            this.tableData_titil.push(table_obj);
            if(this.prop_item.user_level == 1 ){
                table_obj = {label:"公司名",show_value:this.prop_item.company_name,value:this.prop_item.company_name,name:"company_name"};
            }else{
                table_obj = {label:"名称",show_value:this.prop_item.company_name,value:this.prop_item.company_name,name:"company_name"};
            }
            this.tableData_titil.push(table_obj);
            
        },
        


        //提交
        onSubmit(){
            console.log("提交");
            var submit_obj = {};
            submit_obj.cpid = this.prop_item.cpid;
            submit_obj.param="basicUpd";
            submit_obj.cppwd = this.form_data.cppwd;//用户接口密码
            submit_obj.login_pwd = this.form_data.login_pwd;//用户登录密码
            submit_obj.secret = this.form_data.secret;//秘钥
            submit_obj.relationphone = this.form_data.relationphone;//联系人手机号码，多个用半角逗号隔开--核实 （注：在客户端用于下发短信的验证码使用）
            submit_obj.user_type = this.form_data.user_type;//用户付费类型0:预付费类型1：后付费类型
            submit_obj.fail_rtid = this.form_data.fail_rtid;//失败状态返回到的cpid,空不返还
            submit_obj.ip = this.form_data.ip;//客户鉴权ip地址(多个用半角逗号隔开)
            submit_obj.interface_type = this.form_data.interface_type;//接入方式，0:CMPP；1:HTTP
            submit_obj.qxt_http_type = this.form_data.qxt_http_type;//http对接类别表id,空表示没有,只有接入方式(interface_type)是http时显示 例如：zhe_jiang_cai_niao
            submit_obj.url = this.form_data.url;//回调地址
            submit_obj.mourl = this.form_data.mourl;//上行地址（即上行是回调用）
            submit_obj.blanceCallNum = this.form_data.blanceCallNum;//余额提醒  -1:不提醒，大于0则按照数目提醒  一级账号提醒的单位是分，二级和三级账号是条数
            submit_obj.phones = this.form_data.phones;//余额提醒目标号码，多个用半角逗号隔开
            submit_obj.remark = this.form_data.remark;//备注 ，在用户统计里显示

            // console.log("this.form_data.blanceCallNum:"+this.form_data.blanceCallNum);
            // console.log("submit_obj.blanceCallNum:"+submit_obj.blanceCallNum);

            if(API.isEmtry(submit_obj.cppwd)){
                this.$message.error("请填写接口密码");
                return;
            }
            if(API.isEmtry(submit_obj.login_pwd)){
                this.$message.error("请填写登录密码");
                return;
            }
            if(submit_obj.interface_type == 1){//接入方式，0:CMPP；1:HTTP
                if(API.isEmtry(submit_obj.qxt_http_type)){
                    this.$message.error("请选择http对接协议");
                    return;
                }
            }else{
                if(submit_obj.interface_type == 0){//接入方式，0:CMPP；1:HTTP
                    submit_obj.qxt_http_type = "";//cmpp协议时，置空
                }
            }

            // return;
            if(submit_obj.user_type == 1){//后付费
                submit_obj.blanceCallNum = -1;
                submit_obj.phones = "";
            }else{
                //console.log("submit_obj.blanceCallNum:"+submit_obj.blanceCallNum+"|"+String.valueOf(submit_obj.blanceCallNum));
                if(API.isEmtry(submit_obj.blanceCallNum)){
                     this.$message.error("请填写余额提醒阈值");
                     return;
                }else{
                    if(this.form_data.blanceCallNum_onoff == 0){//blanceCallNum_onoff:0,//余额提醒开关，0：关闭；1：打开){
                        submit_obj.blanceCallNum = -1;
                        submit_obj.phones = "";
                    }else{
                        if(API.isEmtry(submit_obj.phones)){
                            this.$message.error("请填写余额提醒目标号码");
                            return;
                        }
                    }
                }
            }

            if(submit_obj.fail_rtid == "no"){//失败状态返回到的cpid,空不返还
                submit_obj.fail_rtid = "";
            }else{
                submit_obj.fail_rtid = submit_obj.cpid;
            }

            API.UserServlet(submit_obj).then((res) => {
                
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //获取 http对接类别 列表数据
        getHttpTypeDataList(){
            //请求接口
            API.UserServlet({
                param: "getHttpTypeDataList"
            }).then((res) => {
                if (res.recode === 0) {
                    this.httpTypeList = res.list;
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>