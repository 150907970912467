<template>
<!-- 客户详情页 -->
    <div id="app_user_detail">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                
            </div>
            <div class="head_top_title">客户详情</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 0px;background-color: white;">
            <el-row style="overflow: auto;" :style="{height: (content_heigth+'px')}">

                <el-row class="dialog_top cursor" @click.native="show_base = !show_base" style="text-align: left;padding-left:15px;">
                    <span>基本信息</span>
                    <i class="dialog_top_close_button" :class="show_base ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px"></i>
                </el-row>
                <!-- 基本信息 -->
                <el-table v-show="show_base" :data="tableData_base" :show-header="false"  style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="label"  width="130"  align="left"> </el-table-column>
                    <!-- <el-table-column  prop="show_value"  min-width="200" align="right"> </el-table-column> -->
                    <el-table-column  min-width="200" align="right">
                        <template slot-scope="scope">
                            <template v-if="scope.row.name == 'states'">
                                <span>{{scope.row.show_value}}</span>
                                <el-button type="text" size="mini" @click="state_upd()"  style="margin-left:10px;">修改</el-button>
                            </template>
                            <template v-else>
                                {{scope.row.show_value}}
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 基本属性修改按钮 -->
                <el-row v-show="show_base" type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                     <el-button type="primary" size="mini" style="padding:10px 50px;" @click="base_upd()">修改</el-button>
                </el-row>

                <el-row class="dialog_top cursor" @click.native="show_high = !show_high" style="text-align: left;padding-left:15px;">
                    <span>高级属性</span>
                    <i class="dialog_top_close_button" :class="show_high ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px"></i>
                </el-row>
                <!-- 高级属性 -->
                <el-table v-show="show_high" :data="tableData_high" :show-header="false"  style="width: 100%"  size="mini" >
                    <el-table-column  fixed  prop="label"  width="130"  align="left"> </el-table-column>
                    <el-table-column  min-width="200" align="right">
                        <template slot-scope="scope">
                            <template v-if="scope.row.name == 'channel_select_type' && detail_obj.channel_select_type == 0">
                                <span>{{scope.row.show_value}}</span>
                                <el-button type="text" size="mini" @click="tableData_channel_group_isshow = !tableData_channel_group_isshow"  style="margin-left:10px;">{{tableData_channel_group_isshow ? '折叠' : '展示'}}</el-button>
                            </template>
                            <template v-else-if="scope.row.name == 'send_citys_array'">
                                <span v-html="scope.row.show_value"></span>
                            </template>
                            <template v-else>
                                {{scope.row.show_value}}
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 指定通道组列表 -->
                <el-table v-show="show_high && tableData_channel_group_isshow" :data="tableData_channel_group" :show-header="false"  style="width: 100%"  size="mini" >
                    <el-table-column  min-width="110"  align="center">
                        <template slot-scope="scope">
                           {{scope.row.channel_name}}(ID:{{scope.row.channel_id}})
                        </template>
                    </el-table-column>
                    <el-table-column  width="100" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini"  @click="channel_detail(scope.row.channel_id)">通道详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 高级属性修改按钮 -->
                <el-row v-if="show_high" type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                     <el-button type="primary" size="mini" style="padding:10px 50px;" @click="high_upd()">修改</el-button>
                </el-row>

                <!-- 子账号 -->
                <el-row v-if="detail_obj.sonCpidArray && detail_obj.sonCpidArray.length>0" class="dialog_top cursor" @click.native="cpid_son_show(detail_obj.cpid)" style="text-align: left;padding-left:15px;">
                    <el-badge :is-dot="detail_obj.sonCpidArray.length>0" style="">子账号</el-badge>
                    <i class="dialog_top_close_button" :class="show_high ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px"></i>
                </el-row>

                <!-- 客户价格 -->
                <el-row  class="dialog_top cursor" @click.native="user_price()" style="text-align: left;padding-left:15px;">
                    <span>客户价格</span>
                    <i class="dialog_top_close_button el-icon-arrow-right" style="font-size:24px"></i>
                </el-row>


                <!-- 分流属性 -->
                <el-row class="dialog_top cursor" @click.native="show_shunt = !show_shunt" style="text-align: left;padding-left:15px;">
                    <el-badge :is-dot="tableData_shunt.length>1" style="">分流属性</el-badge>
                    <i class="dialog_top_close_button" :class="show_shunt ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px"></i>
                </el-row>
                <!-- 分流属性列表 -->
                <el-table v-show="show_shunt" :data="tableData_shunt" :show-header="true"  style="width: 100%"  size="mini" >
                    <el-table-column label="分流比例(%)" prop="shunt_rate"  min-width="110"  align="center"> </el-table-column>
                    <el-table-column label="分流账号" prop="cpid_shunt"  min-width="110"  align="center"> </el-table-column>
                    <el-table-column label="操作" width="100" align="center">
                        <template slot-scope="scope">
                            <el-link v-if="scope.$index > 0" type="danger" @click="del_shunt_cpid(scope.row.cpid_shunt)">删除</el-link>
                            <el-link v-if="scope.$index > 0" type="primary"  style="margin:0 0px 0 10px" @click="shunt_cpid_detail(scope.row.cpid_shunt)">详情</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分流属性按钮 -->
                <el-row v-show="show_shunt" type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                     <el-button type="primary" size="mini" style="padding:10px 20px;" @click="shunt_add()">添加分流账号</el-button>
                     <el-button type="primary" size="mini" style="padding:10px 20px;" @click="shunt_rate_upd()">修改分流比例</el-button>
                </el-row>

                <!-- 百分比通道组 -->
                <el-row class="dialog_top cursor" @click.native="show_percent = !show_percent" style="text-align: left;padding-left:15px;">
                    <el-badge :is-dot="tableData_percent.length>1">百分比通道组</el-badge>
                    <i class="dialog_top_close_button" :class="show_percent ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px">
                    </i>
                </el-row>
                <!-- 百分比通道组列表 -->
                <el-table v-show="show_percent" :data="tableData_percent" :show-header="true"  style="width: 100%"  size="mini" >
                    <el-table-column label="通道id" prop="channel_id" min-width="110" align="center"> </el-table-column>
                    <el-table-column label="通道" prop="channel_name" min-width="110" align="center"> </el-table-column>
                    <el-table-column label="百分比(%)" prop="rate"  min-width="110" align="center"> </el-table-column>
                    <el-table-column label="操作" width="100" align="center">
                        <template slot-scope="scope">
                            <el-link type="primary" style="margin:0 10px 0 0px" @click="percentUpd(scope.row)">修改</el-link>
                            <el-link type="danger" @click="percentDel(scope.row.channel_id)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 百分比通道组按钮 -->
                <el-row v-show="show_percent" type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                     <el-button type="primary" size="mini" style="padding:10px 20px;" @click="percentAdd()">添加百分比通道</el-button>
                </el-row>

            </el-row>
        </el-row>
        
        <!-- 客户基本信息修改组件 -->
        <UserUpdBase :prop_change_i="prop_user_upd_base_i"
            :prop_item="detail_obj"
            @result="userUpdResult"
        ></UserUpdBase>

        <!-- 客户高级属性修改组件 -->
        <UserUpdHigh :prop_change_i="prop_user_upd_high_i"
            :prop_item="detail_obj"
            @result="userUpdResult"
        ></UserUpdHigh>

        <!-- 客户状态修改组件 -->
        <UserUpdState :prop_change_i="prop_user_upd_state_i"
            :prop_item="detail_obj"
            @result="userUpdResult"
        ></UserUpdState>

        <!-- 客户价格组件 -->
        <UserPriceList :prop_change_i="prop_user_price_i" :prop_cpid="prop_user_price_cpid"></UserPriceList>

        <!-- 通道详情-弹出层组件 -->
        <ChannelDetailSelect :prop_change_i="prop_channel_detail_i" :prop_channel_id="prop_channel_detail_id"></ChannelDetailSelect>

        <!-- 客户分流属性分流账号-增加或修改组件 -->
        <UserShuntAddUpd :prop_change_i="prop_shunt_add_upd_i"
            :prop_item="detail_obj"
            :prop_type="prop_shunt_add_upd_type"
            @result="userShuntAddUpdResult"
        ></UserShuntAddUpd>

        <!-- 客户分流账号-详情组件 -->
        <UserShuntDetail :prop_change_i="prop_shunt_cpid_detail_i"
            :prop_item="prop_shunt_cpid_detail_item"
        ></UserShuntDetail>

        <!-- 客户分流比例修改组件 -->
        <UserShuntRateUpd :prop_change_i="prop_shunt_rate_upd_i"
            :prop_item="prop_shunt_rate_upd_item"
            :prop_cpid="detail_obj.cpid"
            @result="shunt_rate_upd_result"
        ></UserShuntRateUpd>

        <!-- 客户百分比通道组-增加或修改组件 -->
        <UserPercentAddUpd :prop_change_i="prop_percent_add_upd_i"
            :prop_item="prop_percent_add_upd_item"
            :prop_type="prop_percent_add_upd_type"
            @result="userPercentAddUpdResult"
        ></UserPercentAddUpd>
        

    </div>
</template>
<script>
import UserUpdBase from './user_upd_base.vue';//组件 客户基本信息修改
import UserUpdHigh from './user_upd_high.vue';//组件 客户高级属性修改
import UserPriceList from './user_price_list.vue';//组件 客户价格
import UserUpdState from './user_upd_state.vue';//组件 客户状态修改
import ChannelDetailSelect from '../channel/channel_detail_select.vue';//组件 通道详情-弹出层
import UserShuntAddUpd from './user_shunt_add_upd.vue';//客户分流属性分流账号-增加或修改组件
import UserShuntDetail from './user_shunt_detail.vue';//客户分流账号-详情组件
import UserShuntRateUpd from './user_shunt_rate_upd.vue';//客户分流比例修改组件
import UserPercentAddUpd from './user_percent_add_upd.vue';//客户百分比通道组组件
import API from '../../api/api';

export default {
    components: {
       UserUpdBase,
       UserUpdHigh,
       UserPriceList,
       UserUpdState,
       ChannelDetailSelect,
       UserShuntAddUpd,
       UserShuntDetail,
       UserShuntRateUpd,
       UserPercentAddUpd
    },

    data(){
        return{
            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            detail_obj : {},//客户详情的对象  通过请求接口获取

            show_base:false,//客户基本信息 是否展示  true:展示
            show_high:false,//客户高级属性 是否展示  true:展示
            show_shunt:false,//客户分流属性 是否展示  true:展示
            show_percent:false,//客户百分比通道组 是否展示  true:展示
            tableData_base:[],//客户基本信息数据
            tableData_high:[],//客户高级属性数据
            tableData_shunt:[],//客户分流属性数据
            tableData_percent:[],//客户百分比通道组数据
            tableData_channel_group:[],//客户指定通道组
            tableData_channel_group_isshow:false,//客户指定通道组是否展示  true:展示

            //客户基本信息修改
            prop_user_upd_base_i:0,//弹出框是否可见

            //客户状态修改
            prop_user_upd_state_i:0,//弹出框是否可见

            //客户高级属性修改
            prop_user_upd_high_i:0,//弹出框是否可见

            //客户价格
            prop_user_price_i:0,//弹出框是否可见
            prop_user_price_cpid:"",//

            //通道详情
            prop_channel_detail_i:0,//弹出框是否可见
            prop_channel_detail_id:"",//通道id

            //客户分流属性分流账号-增加或修改
            prop_shunt_add_upd_i:0,//弹出框是否可见
            prop_shunt_add_upd_type:'add',//客户分流属性分流账号-增加或修改  组件 类型  add：增加  upd:修改

            //客户分流账号-详情
            prop_shunt_cpid_detail_i:0,//弹出框是否可见
            prop_shunt_cpid_detail_item:{},

            //客户分流比例修改
            prop_shunt_rate_upd_i:0,//弹出框是否可见
            prop_shunt_rate_upd_item:{},

            //客户百分比通道组-增加或修改
            prop_percent_add_upd_i:0,//弹出框是否可见
            prop_percent_add_upd_item:{},
            prop_percent_add_upd_type:'add',//客户百分比通道组-增加或修改  组件 类型  add：增加  upd:修改

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        console.log('实例被激活时使用，用于重复激活一个实例的时候')
        // this.detail_obj = this.$options.data().detail_obj;//单个恢复初始化
        // this.show_base = this.$options.data().show_base;//单个恢复初始化
        // this.show_high = this.$options.data().show_high;//单个恢复初始化
        // this.show_shunt = this.$options.data().show_shunt;//单个恢复初始化
        // this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化
        // this.tableData_high = this.$options.data().tableData_high;//单个恢复初始化
        // this.tableData_shunt = this.$options.data().tableData_shunt;//单个恢复初始化
        // this.tableData_channel_group = this.$options.data().tableData_channel_group;//单个恢复初始化
        // this.tableData_channel_group_isshow = this.$options.data().tableData_channel_group_isshow;//单个恢复初始化

        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));

        //初始化设置
        this.initSetUp();

        //获取数据
        this.getData();
    },
    // deactivated () {
    //     console.log('实例没有被激活时')
    // },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },

        //子账号的展示
        cpid_son_show(cpid){
            console.log("子账号的展示"+cpid);
            API.router_to("/user_son_list/"+cpid+"/000000");
        },

        //通道详情
        channel_detail(channel_id){
            console.log("通道详情"+channel_id);
            this.prop_channel_detail_id = channel_id;//通道id
            this.prop_channel_detail_i ++;//弹出框是否可见
        },

        //基本信息修改
        base_upd(){
            console.log("基本信息修改");
            if(this.detail_obj.cpid){
                this.prop_user_upd_base_i ++;//弹出框是否可见
            }

        },
        //高级属性修改
        high_upd(){
            console.log("高级属性修改");
            if(this.detail_obj.cpid){
                this.prop_user_upd_high_i ++;//弹出框是否可见
            }
        },
        //客户分流属性分流账号-增加
        shunt_add(){
            console.log("客户分流属性分流账号-增加");
            if(this.detail_obj.cpid){
                this.prop_shunt_add_upd_i ++;//弹出框是否可见
            }
        },
        //客户分流属性分流账号-结果
        userShuntAddUpdResult(){
            this.getData();
        },

        //删除-分流账号
        del_shunt_cpid(cpid_shunt){
            console.log("删除-分流账号："+cpid_shunt);
            var cpid = this.$route.params.id;

            this.$confirm('此操作将永久删除该分流账号, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    //请求接口
                    API.UserShuntServlet({
                        param: "del",
                        cpid_shunt:cpid_shunt,
                        cpid:cpid,
                    }).then((res) => {
                        if (res.recode === 0) {
                            this.$message({showClose: true,message: '恭喜你，分流账号删除成功', type: 'success'});
                            //重新加载数据
                            this.getData();
                        }
                    });
            }).catch(() => {//已取消删除
            });
        },

        //客户分流账号-详情
        shunt_cpid_detail(cpid_shunt){
            console.log("客户分流账号-详情");
            
            var cpid = this.$route.params.id;
            this.prop_shunt_cpid_detail_item.cpid = cpid;
            this.prop_shunt_cpid_detail_item.cpid_shunt = cpid_shunt;

            this.prop_shunt_cpid_detail_i ++;//弹出框是否可见
        },

        //客户分流比例修改
        shunt_rate_upd(){
            console.log("客户分流比例修改");
            console.log(this.tableData_shunt);
            this.prop_shunt_rate_upd_item.tableData_shunt = this.tableData_shunt;
            this.prop_shunt_rate_upd_i ++;//弹出框是否可见
        },
        shunt_rate_upd_result(){
            //获取数据
            this.getData();
        },



        //客户状态修改
        state_upd(){
            console.log("状态修改");
            if(this.detail_obj.cpid){
                this.prop_user_upd_state_i ++;//弹出框是否可见
            }

        },
        //修改结果
        userUpdResult(){
            this.getData();
        },
        

        //客户价格
        user_price(){
            console.log("客户价格");
            if(this.detail_obj.cpid){
                this.prop_user_price_cpid = this.detail_obj.cpid;
                this.prop_user_price_i ++;//弹出框是否可见
            }
        },

        //客户百分比通道组-增加
        percentAdd(){
            if(this.detail_obj.cpid){
                this.prop_percent_add_upd_item.cpid = this.$route.params.id;
                this.prop_percent_add_upd_i ++;//弹出框是否可见
                this.prop_percent_add_upd_type = 'add';
            }
        },

        //客户百分比通道组-修改
        percentUpd(item){
            if(this.detail_obj.cpid){
                this.prop_percent_add_upd_item.cpid = this.$route.params.id;
                this.prop_percent_add_upd_item.channel_id = item.channel_id;
                this.prop_percent_add_upd_item.channel_name = item.channel_name;
                this.prop_percent_add_upd_item.rate = item.rate;
                this.prop_percent_add_upd_i ++;//弹出框是否可见
                this.prop_percent_add_upd_type = 'upd';
            }
        },

        //客户百分比通道组-结果
        userPercentAddUpdResult(){
            this.getData();
        },

        //删除-客户百分比通道
        percentDel(channel_id){
            var cpid = this.$route.params.id;

            this.$confirm('此操作将永久删除该客户百分比通道, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    //请求接口
                    API.UserServlet({
                        param: "percentChannelGroupDel",
                        cpid:cpid,
                        channel_id:channel_id,
                    }).then((res) => {
                        if (res.recode === 0) {
                            this.$message({showClose: true,message: '恭喜你，客户百分比通道删除成功', type: 'success'});
                            //重新加载数据
                            this.getData();
                        }
                    });
            }).catch(() => {//已取消删除
            });
        },

        //获取数据
        getData(){
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化
            this.tableData_high = this.$options.data().tableData_high;//单个恢复初始化
            this.tableData_channel_group = this.$options.data().tableData_channel_group;//单个恢复初始化

            var cpid = this.$route.params.id;
            //请求接口
            API.UserServlet({
                param: "detail",
                cpid:cpid,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    this.detail_obj = res.detail;
                    this.tableData_channel_group =  res.detail.user_channel_groups;////通道选取方式为指定通道组时，指定的通道列表
                    var show_value = "";


                    var table_obj = {label:"客户账号",show_value:res.detail.cpid,value:res.detail.cpid,name:"cpid"};
                    this.tableData_base.push(table_obj);

                    if( 1 == 1){
                        table_obj = {label:"公司名",show_value:res.detail.company_name,value:res.detail.company_name,name:"company_name"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"公司商务类型",show_value:res.detail.business_type == 0 ? '渠道' : '直客',value:res.detail.business_type,name:"business_type"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"公司鉴权IP",show_value:res.detail.ip == '' ? res.detail.company_ip : '无效',value:res.detail.company_ip,name:"company_ip"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"公司累计充值",show_value:res.detail.nummax,value:res.detail.nummax,name:"nummax"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"公司剩余金额",show_value:res.detail.blanceNum,value:res.detail.blanceNum,name:"blanceNum"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"登录密码",show_value:res.detail.login_pwd,value:res.detail.login_pwd,name:"login_pwd"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"接口密码",show_value:res.detail.cppwd,value:res.detail.cppwd,name:"cppwd"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"私钥",show_value:res.detail.secret,value:res.detail.secret,name:"secret"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"联系方式",show_value:res.detail.relationphone,value:res.detail.relationphone,name:"relationphone"};
                        this.tableData_base.push(table_obj);

                        if(res.detail.states == 0){
                            show_value = "可用";
                        }else if(res.detail.states == 1){
                            show_value = "注销";
                        }else if(res.detail.states == 2){
                            show_value = "已删除";
                        }else if(res.detail.states == 3){
                            show_value = "暂停";
                        }else{
                            show_value = "未知";
                        }
                        table_obj = {label:"客户状态",show_value:show_value,value:res.detail.states,name:"states"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户付费类型",show_value:res.detail.user_type == 0 ? '预付费' : '后付费',value:res.detail.user_type,name:"user_type"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"失败是否返还",show_value:res.detail.fail_rtid == '' ? '不返还' : '返还',value:res.detail.fail_rtid,name:"fail_rtid"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户鉴权IP",show_value:res.detail.ip,value:res.detail.ip,name:"ip"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户接入方式",show_value:res.detail.interface_type == 0 ? 'CMPP' : 'HTTP',value:res.detail.interface_type,name:"interface_type"};
                        this.tableData_base.push(table_obj);



                        if(res.detail.interface_type != 0){//接入方式，0:CMPP；1:HTTP

                            table_obj = {label:"http对接协议",show_value:res.detail.qxt_http_type_name,value:res.detail.qxt_http_type_name,name:"qxt_http_type_name"};
                            this.tableData_base.push(table_obj);

                            table_obj = {label:"回调地址",show_value:res.detail.url,value:res.detail.url,name:"url"};
                            this.tableData_base.push(table_obj);

                            table_obj = {label:"上行地址",show_value:res.detail.mourl,value:res.detail.mourl,name:"mourl"};
                            this.tableData_base.push(table_obj);
                        }

                        if(res.detail.user_type == 0){//预付费
                            table_obj = {label:"余额提醒",show_value:res.detail.blanceCallNum == -1 ? '不提醒' : '提醒',value:res.detail.blanceCallNum,name:"blanceCallNum"};
                            this.tableData_base.push(table_obj);
                            if(res.detail.blanceCallNum != -1){
                                table_obj = {label:"提醒金额(分)",show_value:res.detail.blanceCallNum+'分',value:res.detail.blanceCallNum,name:"blanceCallNum"};
                                this.tableData_base.push(table_obj);
                                table_obj = {label:"提醒目标号码",show_value:res.detail.phones,value:res.detail.phones,name:"phones"};
                                this.tableData_base.push(table_obj);
                            }
                        }

                        table_obj = {label:"关联微信",show_value:res.detail.wxArray.length,value:res.detail.wxArray.length,name:"wxArray"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"已发送条数",show_value:res.detail.havedNum,value:res.detail.havedNum,name:"havedNum"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"当日已发条数",show_value:res.detail.currentNum,value:res.detail.currentNum,name:"currentNum"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"备注",show_value:res.detail.remark,value:res.detail.remark,name:"remark"};
                        this.tableData_base.push(table_obj);
                    }

                    

                    //------高级-----------------------------------------------------------------------------------------------
                    if(2 == 2){
                        table_obj = {label:"未知返还比例",show_value:res.detail.unknownReturn+"%",value:res.detail.unknownReturn,name:"unknownReturn"};
                        this.tableData_high.push(table_obj);

                        if(res.detail.yld_level == 1){
                            show_value = "验证码";
                        }else if(res.detail.yld_level == 2){
                            show_value = "行业";
                        }else if(res.detail.yld_level == 3){
                            show_value = "营销";
                        }else if(res.detail.yld_level == 4){
                            show_value = "四类";
                        }else{
                            show_value = "未知";
                        }
                        table_obj = {label:"客户类型",show_value:show_value,value:res.detail.yld_level,name:"yld_level"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.yld_level == 1 || res.detail.yld_level == 2){
                            table_obj = {label:"行业发营销监控",show_value:res.detail.hy_send_yx_onoff == 0 ? '打开' : '关闭',value:res.detail.hy_send_yx_onoff,name:"hy_send_yx_onoff"};
                            this.tableData_high.push(table_obj);
                            if(res.detail.hy_send_yx_onoff == 0 ){//行业发营销监控开关  0:开；1:关
                                table_obj = {label:"行业发营销最低利润",show_value:res.detail.hy_send_yx_profit_money+'分/条',value:res.detail.hy_send_yx_profit_money,name:"hy_send_yx_profit_money"};
                                this.tableData_high.push(table_obj);
                            }
                        }

                       
                        if(res.detail.sms_type != 2){//多媒体短信
                            table_obj = {label:"回T退订",show_value:res.detail.isT == 0 ? '关闭' : '打开',value:res.detail.isT,name:"isT"};
                            this.tableData_high.push(table_obj);
                        }
                        

                        table_obj = {label:"日限",show_value:res.detail.isDayNum == -1 ? '不限' : res.detail.isDayNum+'条/天',value:res.detail.isDayNum,name:"isDayNum"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"号码限流",show_value:res.detail.isminutetime == 0 ? '不限制' : res.detail.isminutenum+'条/'+res.detail.isminutetime+'分钟',value:res.detail.isminutetime,name:"isminutetime"};
                        this.tableData_high.push(table_obj);

                        if(res.detail.sms_type != 2){//多媒体短信
                            table_obj = {label:"签名位置",show_value:res.detail.sign_position == 0 ? '强制前置' : res.detail.sign_position == 1 ? '强制后置' : '不管签名位置',value:res.detail.sign_position,name:"sign_position"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"自动加签名",show_value:res.detail.sign_add == '' ? '不加' : res.detail.sign_add,value:res.detail.sign_add,name:"sign_add"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"双签名修改开关",show_value:res.detail.isDoublesign == 0 ? '关闭' : '打开',value:res.detail.isDoublesign,name:"isDoublesign"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"头尾双签名拒发开关",show_value:res.detail.is_top_end_sign == 0 ? '关闭' : '打开',value:res.detail.is_top_end_sign,name:"is_top_end_sign"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"验证码过滤词",show_value:res.detail.yzmKeyword == 0 ? '不验证' : res.detail.yzmKeyword == 1 ? '包含转人工' : '包含拦截',value:res.detail.yzmKeyword,name:"yzmKeyword"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.yzmKeyword != 0 ){
                            table_obj = {label:"验证码过滤词库",show_value:res.detail.keywords_id_YZMName,value:res.detail.keywords_id_YZMName,name:"keywords_id_YZMName"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"账号过滤词",show_value:res.detail.isKeyword == 0 ? '不验证' : res.detail.isKeyword == 1 ? '包含转人工' : '包含拦截',value:res.detail.remark,name:"remark"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.isKeyword != 0 ){
                            table_obj = {label:"账号过滤词库",show_value:res.detail.keywordGroupName,value:res.detail.keywordGroupName,name:"keywordGroupName"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"自有黑名单",show_value:res.detail.selfBlack == 0 ? '关闭' : '打开',value:res.detail.selfBlack,name:"selfBlack"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"运营商黑名单",show_value:res.detail.operBlack == 0 ? '关闭' : '打开',value:res.detail.operBlack,name:"operBlack"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"回T黑名单",show_value:res.detail.backBlack == 0 ? '关闭' : '打开',value:res.detail.backBlack,name:"backBlack"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"三方黑名单",show_value:res.detail.black_three_id_array_name == '' ? '无': res.detail.black_three_id_array_name,value:res.detail.black_three_id_array,name:"black_three_id_array"};
                        this.tableData_high.push(table_obj);

                        if(res.detail.interface_type == 1 && res.detail.qxt_http_type == 'system'){
                            
                            table_obj = {label:"http长短信状态推送",show_value:res.detail.isReportPush == 0 ? '单条' : '多条',value:res.detail.isReportPush,name:"isReportPush"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"http返回状态时间",show_value:res.detail.push_state_time == 0 ? '不推送' : '推送',value:res.detail.push_state_time,name:"push_state_time"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"http推送计费条数",show_value:res.detail.push_snum == 0 ? '不推送' : '推送',value:res.detail.push_snum,name:"push_snum"};
                            this.tableData_high.push(table_obj);

                        }

                        if(res.detail.interface_type == 0){////接入方式，0:CMPP；1:HTTP
                            table_obj = {label:"CMPP最大发送速度",show_value:res.detail.speedMax+'条/秒',value:res.detail.speedMax,name:"speedMax"};
                            this.tableData_high.push(table_obj);
                        }

                        if(res.detail.sms_type == 0){
                            show_value = "短信";
                        }else if(res.detail.sms_type == 1){
                            show_value = "语音";
                        }else if(res.detail.sms_type == 2){
                            show_value = "多媒体短信";
                        }else{
                            show_value = "未知";
                        }

                        table_obj = {label:"客户短信类型",show_value:show_value,value:res.detail.sms_type,name:"sms_type"};
                        this.tableData_high.push(table_obj);

                        if(res.detail.sms_type == 0){//客户短信类型:0:短信
                            table_obj = {label:"固话短信开关",show_value:res.detail.fixed_line_onoff == 0 ? '关闭' : '打开',value:res.detail.fixed_line_onoff,name:"fixed_line_onoff"};
                            this.tableData_high.push(table_obj);

                            if(res.detail.fixed_line_onoff == 1){//固话短信开关 0：关闭；1：打开 ,只有当发送类别选择短信时，可显示
                                table_obj = {label:"固话短信可发省份",show_value:res.detail.fixed_line_province_str,value:res.detail.fixed_line_province,name:"fixed_line_province"};
                                this.tableData_high.push(table_obj);
                            }
                        }

                        table_obj = {label:"自定义扩展码",show_value:res.detail.own_ext == '' ? '不允许' : res.detail.own_ext,value:res.detail.own_ext,name:"own_ext"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"签名库",show_value:res.detail.sign_library_id == 0 ? '不验证签名库' : res.detail.sign_library_name,value:res.detail.sign_library_name,name:"sign_library_name"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.sign_library_id != 0){
                            table_obj = {label:"签名扩展类型",show_value:res.detail.sign_ext_onoff == 0 ? '使用签名库扩展' : res.detail.sign_ext_onoff == 1 ? '使用签名库扩展+客户扩展' : '使用客户扩展',value:res.detail.sign_ext_onoff,name:"sign_ext_onoff"};
                            this.tableData_high.push(table_obj);
                            table_obj = {label:"签名库自加签名",show_value:res.detail.sign_after == 0 ? '允许' : '不允许',value:res.detail.sign_after,name:"sign_after"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"移动",show_value:res.detail.yd_refuse == 0 ? '可发' : '拒发',value:res.detail.yd_refuse,name:"yd_refuse"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"联通",show_value:res.detail.lt_refuse == 0 ? '可发' : '拒发',value:res.detail.lt_refuse,name:"lt_refuse"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"电信",show_value:res.detail.dx_refuse == 0 ? '可发' : '拒发',value:res.detail.dx_refuse,name:"dx_refuse"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"可发省份",show_value:res.detail.send_pro_str,value:res.detail.send_pro,name:"send_pro"};
                        this.tableData_high.push(table_obj);

                        if(res.detail.send_citys_array.length > 0){
                            table_obj = {label:"可发省份下的地市",show_value:res.detail.send_citys_array_str,value:res.detail.send_citys_array,name:"send_citys_array"};
                            this.tableData_high.push(table_obj);
                        }

                        if(res.detail.sms_type != 2){//非为多媒体短信

                            table_obj = {label:"验证码识别开关",show_value:res.detail.checknum_onoff == 0 ? '关闭' : '打开',value:res.detail.checknum_onoff,name:"checknum_onoff"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"全局模板开关",show_value:res.detail.all_template_onoff == 0 ? '关闭' : '打开',value:res.detail.all_template_onoff,name:"all_template_onoff"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"强制不审核开关",show_value:res.detail.ischeck == 0 ? '关闭' : '打开',value:res.detail.ischeck,name:"ischeck"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"客户接入码",show_value:res.detail.access_code == '' ? '无' : res.detail.access_code,value:res.detail.access_code,name:"access_code"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"客户平台权限",show_value:res.detail.user_power == '' ? '无' : '自助下行导出',value:res.detail.user_power,name:"user_power"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"预警策略",show_value:res.detail.warning_id == 0 ? '不预警' : res.detail.warning_name,value:res.detail.warning_id,name:"warning_id"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"是否支持携号转网",show_value:res.detail.change_oper_onoff == 0 ? '不支持' : '支持',value:res.detail.change_oper_onoff,name:"change_oper_onoff"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"是否允许带链接",show_value:res.detail.link_is == 0 ? '允许' : '不允许',value:res.detail.link_is,name:"link_is"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.link_is == 0){
                            table_obj = {label:"带链接的信任级别",show_value:res.detail.link_trust_level == 0 ? '信任' : '不信任',value:res.detail.link_trust_level,name:"link_trust_level"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"客户重发策略",show_value:res.detail.user_repeat_strategy_id == 0 ? '不重发' : res.detail.user_repeat_strategy_name,value:res.detail.user_repeat_strategy_id,name:"user_repeat_strategy_id"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"要求扩展码位数",show_value:res.detail.ext_num == 0 ? '不要求' : res.detail.ext_num+'位',value:res.detail.ext_num,name:"ext_num"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"上行是否要求",show_value:res.detail.mo_onoff == 0 ? '要求' : '不要求',value:res.detail.mo_onoff,name:"mo_onoff"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"特殊分类标签",show_value:res.detail.special_label == '' ? '无特殊标签' : res.detail.special_label,value:res.detail.special_label,name:"special_label"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"是否允许带联系方式",show_value:res.detail.contact_onoff == 0 ? '允许' : '不允许',value:res.detail.contact_onoff,name:"contact_onoff"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"是否允许发送纯英文",show_value:res.detail.pure_english_onoff == 0 ? '允许' : '不允许',value:res.detail.pure_english_onoff,name:"pure_english_onoff"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"英文签名是否可发",show_value:res.detail.english_sign_onoff == 0 ? '不可发' : '可发',value:res.detail.english_sign_onoff,name:"english_sign_onoff"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"单条最低利润",show_value:res.detail.profit_min+"分",value:res.detail.profit_min,name:"profit_min"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"单日赔钱最大值",show_value:res.detail.day_pay_max+"元",value:res.detail.day_pay_max,name:"day_pay_max"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"当日赔钱数",show_value:res.detail.pay_money+"元",value:res.detail.pay_money,name:"pay_money"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"审核方式",show_value:res.detail.check_type == 0 ? '免审' : res.detail.check_type == 1 ? '必审' : res.detail.check_type == 2 ? '系统抽样审' : res.detail.check_type == 3 ? '无模板拒发' : '预报备模板',value:res.detail.check_type,name:"check_type"};
                        this.tableData_high.push(table_obj);

                        var send_hhmm_start = res.detail.send_hhmm_start+"";
                        var send_hhmm_end = res.detail.send_hhmm_end+"";
                        var send_hhmm_start_str = "";
                        var send_hhmm_end_str = "";
                        if(send_hhmm_start.length == 1){
                            send_hhmm_start_str = "0点";
                        }if(send_hhmm_start.length == 2){
                            send_hhmm_start_str = "0点半";
                        }else if(send_hhmm_start.length == 3){
                            var send_hhmm_end_2 = send_hhmm_start.substring(1);
                            if(send_hhmm_end_2 == '00'){
                                send_hhmm_start_str = send_hhmm_start.substring(0,1)+"点";
                            }else{
                                send_hhmm_start_str = send_hhmm_start.substring(0,1)+"点半";
                            }
                        }else if(send_hhmm_start.length == 4){
                            var send_hhmm_end_2 = send_hhmm_start.substring(2);
                            if(send_hhmm_end_2 == '00'){
                                send_hhmm_start_str = send_hhmm_start.substring(0,2)+"点";
                            }else{
                                send_hhmm_start_str = send_hhmm_start.substring(0,2)+"点半";
                            }
                        }
                        if(send_hhmm_end.length == 1){
                            send_hhmm_end_str = "0点";
                        }if(send_hhmm_end.length == 2){
                            send_hhmm_end_str = "0点半";
                        }else if(send_hhmm_end.length == 3){
                            var send_hhmm_end_2 = send_hhmm_end.substring(1);
                            if(send_hhmm_end_2 == '00'){
                                send_hhmm_end_str = send_hhmm_end.substring(0,1)+"点";
                            }else{
                                send_hhmm_end_str = send_hhmm_end.substring(0,1)+"点半";
                            }
                        }else if(send_hhmm_end.length == 4){
                            var send_hhmm_end_2 = send_hhmm_end.substring(2);
                            if(send_hhmm_end_2 == '00'){
                                send_hhmm_end_str = send_hhmm_end.substring(0,2)+"点";
                            }else{
                                send_hhmm_end_str = send_hhmm_end.substring(0,2)+"点半";
                            }
                        }
                        show_value = send_hhmm_start_str+"到"+send_hhmm_end_str;
                        table_obj = {label:"可发送时段",show_value:show_value,value:res.detail.send_hhmm_start,name:"send_hhmm_start"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"超时段处理方式",show_value:res.detail.send_timeout_set == 0 ? '延迟发送' : '直接失败',value:res.detail.send_timeout_set,name:"send_timeout_set"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"是否要求指定码号",show_value:res.detail.specify_codenum_onff == 0 ? '要求' : '不要求',value:res.detail.specify_codenum_onff,name:"specify_codenum_onff"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.specify_codenum_onff == 0 ){
                            table_obj = {label:"指定码号",show_value:res.detail.specify_codenum,value:res.detail.specify_codenum,name:"specify_codenum"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"灵狐审核类型",show_value:res.detail.fox_type == -1 ? '不验证' : res.detail.fox_type == 0 ? '极速' : 'AI',value:res.detail.fox_type,name:"fox_type"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.fox_type != -1 ){
                            table_obj = {label:"灵狐抽样比率",show_value:(res.detail.sampling_rate/100)+"%",value:res.detail.sampling_rate,name:"sampling_rate"};
                            this.tableData_high.push(table_obj);
                        }
                        
                        show_value = "";
                        var channel_select_style = res.detail.channel_select_style;//可选通道类别，0:直连；1:准直连；2:三方；3:厂商，复选框(多个使用半角逗号隔开)
                        var channel_select_style_array = channel_select_style.split(",");
                        for(var i =0;i<channel_select_style_array.length;i++){
                            var one = channel_select_style_array[i];
                            if(show_value != ''){
                                show_value += ",";
                            }
                            if(one == '0'){
                                show_value += "直连";
                            }else if(one == '1'){
                                show_value += "准直连";
                            }else if(one == '2'){
                                show_value += "三方";
                            }else if(one == '3'){
                                show_value += "厂商";
                            }
                        }

                        table_obj = {label:"可选通道类型",show_value:show_value,value:res.detail.channel_select_style,name:"channel_select_style"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"发送类型",show_value:res.detail.send_type == '' ? '无' : res.detail.send_type,value:res.detail.send_type,name:"send_type"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"通道选取方式",show_value:res.detail.channel_select_type == 0 ? '指定通道组' : '自动选通道',value:res.detail.send_type,name:"channel_select_type"};
                        this.tableData_high.push(table_obj);


                    }

                    //-----分流---tableData_shunt--------------------
                    if(3 == 3){
                        var shunt_rate_main = 100;//主账号的分流比例
                        for(var item of res.detail.user_shunt_array) {
                            let shunt_rate = item.shunt_rate;
                            shunt_rate_main = shunt_rate_main - shunt_rate;
                        }
                        this.tableData_shunt = res.detail.user_shunt_array;
                        
                        table_obj = {cpid_shunt:"主账号",shunt_rate:shunt_rate_main};
                        this.tableData_shunt.unshift(table_obj);

                    }

                    //-----客户百分比通道组---tableData_percent--------------------
                    if(4 == 4){
                        this.tableData_percent = res.detail.percent_channel_group;
                    }
           
                }
                
            });
        },
        //返回
        goBack() {
            API.router_to("/user_list");
        }
    }
};

</script>

<style scoped>

#app_user_detail >>> .el-badge__content.is-fixed {
  position: inherit;
}
</style>