<template>
<div>
    <!-- 客户分流属性分流账号-增加或修改 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{cur_title}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

           <!-- 客户cpid+公司名 -->
            <el-row type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                <el-table :data="tableData_titil" :show-header="false"  style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="label"  width="110"  align="center"> </el-table-column>
                    <el-table-column  prop="show_value"  min-width="200" align="right"> </el-table-column>
                </el-table>
            </el-row>

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-form  :model="form_data" label-width="140px" style="margin-bottom: 12px;">
                    

                    <el-form-item label="要求扩展码位数">
                        <el-input v-model="form_data.ext_num" placeholder="请填写扩展码位数" size="mini" oninput="value=value.replace(/[^\d.]/g,'')">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="上行是否要求" >
                        <el-radio-group v-model="form_data.mo_onoff" size="mini">
                            <el-radio :label="0">要求</el-radio>
                            <el-radio :label="1">不要求</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="特殊分类标签">
                        <el-select v-model="form_data.special_label" clearable placeholder="选填"  filterable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in special_labelList"
                                :key="item.name"
                                :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="单条最低利润">
                        <el-input v-model="form_data.profit_min" placeholder="请填写单条最低利润" size="mini" style="vertical-align: middle;"
                            oninput="value=value.replace(/[^(-?\d+)(\.\d+)?$]/g,'')">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>

                    
                    <el-form-item label="是否要求指定码号" >
                        <el-radio-group v-model="form_data.specify_codenum_onff" size="mini" @change="form_data.specify_codenum=''">
                            <el-radio :label="0">要求</el-radio>
                            <el-radio :label="1">不要求</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="指定码号" v-show="form_data.specify_codenum_onff == 0">
                        <el-input v-model="form_data.specify_codenum" placeholder="请填写指定码号" size="mini" oninput="value=value.replace(/[^\d.]/g,'')">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="可选通道类别">
                        <el-checkbox-group v-model="form_data.channel_select_style_array" size="mini">
                            <el-checkbox  label="直连"></el-checkbox>
                            <el-checkbox  label="准直连"></el-checkbox>
                            <el-checkbox  label="三方"></el-checkbox>
                            <el-checkbox  label="厂商"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>


                    <el-form-item label="通道选取方式">
                        <el-radio-group v-model="form_data.channel_select_type" size="mini">
                            <el-radio :label="0">指定通道组</el-radio>
                            <el-radio :label="1">自动选通道</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="指定通道" v-show="form_data.channel_select_type == 0">
                        <el-select v-model="form_data.user_channel_id_array" filterable multiple size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in channelList"
                                :key="item.id"
                                :label="item.channel_name+'('+item.id+')'"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                   
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>

            </el-row>

        </el-row>


    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_type:'',//类型 add:增加  upd:修改
        prop_item:{},//公司对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                if(this.prop_type === "upd"){//修改
                    this.cur_title = "客户分流账号的修改";
                    setTimeout(() => {
                        //获取数据
                        this.getData();

                        //请求接口-获取通道列表数据-指定通道组使用的
                        this.getChanenlList();

                        //获取特殊标签数据
                        this.getSpecialLabelList();
                    }, 1);
                }else{
                    this.cur_title = "客户分流账号的增加";
                    setTimeout(() => {
                        //获取数据
                        this.getData();
                        //请求接口-获取通道列表数据-指定通道组使用的
                        this.getChanenlList();

                        //获取特殊标签数据
                        this.getSpecialLabelList();
                    }, 1);
                }
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头

            tableData_titil:[],//客户cpid+公司名
            
            channelList:[],//通道列表数据-指定通道组使用的--请求接口获取
            special_labelList:[],//特殊标签数据-请求接口获取

            form_data:{
                cpid_shunt:"",//用户分流的主键 cpid+"_"+序列id
                cpid:"",//对应t_user表的cpid
                shunt_rate:0,//分流比例 0 - 100区间
                ext_num:0,//要求扩展码位数，最小值为0，0:表示不管扩展码位数
                mo_onoff:1,//上行是否要求，0:要求；1:不要求
                special_label:"",//特殊分类标签，空表示无标签，标签使用文字，例如：游戏
                profit_min:0,//单条最低利润,单位分

                specify_codenum_onff:1,//是否要求指定码号,0:要求；1:不要求
                specify_codenum:"",//指定码号  当要求指定码号时生效

                channel_select_style:"2",//可选通道类别，0:直连；1:准直连；2:三方；3:厂商，复选框(多个使用半角逗号隔开)
                channel_select_style_array:["三方"],//可选通道类别数组
                
                channel_select_type:1,//通道选取方式，0:指定通道组；1:自动选通道
                user_channel_ids:"",//通道选取方式是指定通道组时，通道id数组,多个使用半角逗号隔开
                user_channel_id_array:[],//通道选取方式是指定通道组时,通道id数组 
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
            this.tableData_titil = this.$options.data().tableData_titil;//单个恢复初始化
       
        },
        //查询
        getData(){
            var table_obj = {label:"客户主账号",show_value:this.prop_item.cpid,value:this.prop_item.cpid,name:"cpid"};
            this.tableData_titil.push(table_obj);
            if(this.prop_type === "upd"){//修改
                this.form_data.cpid_shunt = this.prop_item.cpid_shunt;//用户分流的主键 cpid+"_"+序列id

                table_obj = {label:"分流账号",show_value:this.prop_item.cpid_shunt,value:this.prop_item.cpid_shunt,name:"cpid_shunt"};
                this.tableData_titil.push(table_obj);
            }

            this.form_data.cpid = this.prop_item.cpid;//对应t_user表的cpid
            this.form_data.shunt_rate = this.prop_item.shunt_rate;//分流比例 0 - 100区间
            this.form_data.ext_num = this.prop_item.ext_num;//要求扩展码位数，最小值为0，0:表示不管扩展码位数
            this.form_data.mo_onoff = this.prop_item.mo_onoff;//上行是否要求，0:要求；1:不要求
            this.form_data.special_label = this.prop_item.special_label;//特殊分类标签，空表示无标签，标签使用文字，例如：游戏
            this.form_data.profit_min = this.prop_item.profit_min;//单条最低利润,单位分
            this.form_data.specify_codenum_onff = this.prop_item.specify_codenum_onff;//是否要求指定码号,0:要求；1:不要求
            this.form_data.specify_codenum = this.prop_item.specify_codenum;//指定码号  当要求指定码号时生效
            this.form_data.channel_select_style = this.prop_item.channel_select_style;//可选通道类别，0:直连；1:准直连；2:三方；3:厂商，复选框(多个使用半角逗号隔开)
            this.form_data.channel_select_type = this.prop_item.channel_select_type;//通道选取方式，0:指定通道组；1:自动选通道

            this.form_data.user_channel_ids = this.prop_item.user_channel_ids;//通道选取方式是指定通道组时，通道id数组,多个使用半角逗号隔开

            //指定通道组
            if(this.form_data.user_channel_ids != ""){
                this.form_data.user_channel_id_array = [];
                var user_channel_id_array = this.form_data.user_channel_ids.split(",");
                for(var i =0;i<user_channel_id_array.length;i++){
                    this.form_data.user_channel_id_array.push(parseInt(user_channel_id_array[i]));
                }
            }

            //初始化可选通道类别
            this.form_data.channel_select_style_array = [];
            var channel_select_styles = this.prop_item.channel_select_style.split(",");
            for(var i = 0;i<channel_select_styles.length;i++){
                //0:直连；1:准直连；2:三方；3:厂商
                var one = channel_select_styles[i];
                if(one == 0){
                    this.form_data.channel_select_style_array.push("直连");
                }else if(one == 1){
                    this.form_data.channel_select_style_array.push("准直连");
                }else if(one == 2){
                    this.form_data.channel_select_style_array.push("三方");
                }else if(one == 3){
                    this.form_data.channel_select_style_array.push("厂商");
                }
            }

            

            
        },
        //获取通道列表数据-指定通道组使用的
        getChanenlList(){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:"",//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                }
            });
        },
         //获取特殊标签数据
        getSpecialLabelList(){
            //请求接口
            API.LabelServlet({
                param: "specialAllList"
            }).then((res) => {
                if (res.recode === 0) {
                    this.special_labelList = res.list;//特殊标签数据-请求接口获取
                    // var obj = {value: "", name: "无" };
                    // this.special_labelList.unshift(obj);
                }
            });
        },
        



        //提交
        onSubmit(){
            console.log("提交");
            if(API.isEmtry(this.form_data.profit_min)){
                this.$message.error("请填写单条最低利润");
                return;
            }
            if(this.form_data.channel_select_style_array.length == 0){
                this.$message.error("请选择可选通道类别");
                return;
            }else{
                this.form_data.channel_select_style = "";
                for(var i=0;i<this.form_data.channel_select_style_array.length;i++){
                    var v = this.form_data.channel_select_style_array[i];
                    if(this.form_data.channel_select_style != ""){
                        this.form_data.channel_select_style += ",";
                    }
                    if(v == '直连'){
                        this.form_data.channel_select_style += "0";
                    }else if(v == '准直连'){
                        this.form_data.channel_select_style += "1";
                    }else if(v == '三方'){
                        this.form_data.channel_select_style += "2";
                    }else if(v == '厂商'){
                        this.form_data.channel_select_style += "3";
                    }
                }
                // console.log("submit_obj.channel_select_style:"+submit_obj.channel_select_style);
            }
            if(this.form_data.channel_select_type == 0){
                if(this.form_data.user_channel_id_array.length == 0){
                    this.$message.error("请选择指定通道组");
                    return;
                }else{
                    this.form_data.user_channel_ids = this.form_data.user_channel_id_array.toString();
                }
            }else{
                this.form_data.user_channel_ids = "";
            }


            var submit_obj = {};
            if(this.prop_type === "upd"){//修改
                submit_obj.param = "upd";
                submit_obj.cpid_shunt = this.form_data.cpid_shunt;//公司ID
            }else{//增加
                submit_obj.param = "add";
            }
            submit_obj.cpid = this.form_data.cpid;//对应t_user表的cpid
            submit_obj.shunt_rate = this.form_data.shunt_rate;//分流比例 0 - 100区间
            submit_obj.ext_num = this.form_data.ext_num;//要求扩展码位数，最小值为0，0:表示不管扩展码位数
            submit_obj.mo_onoff = this.form_data.mo_onoff;//上行是否要求，0:要求；1:不要求
            submit_obj.special_label = this.form_data.special_label;//特殊分类标签，空表示无标签，标签使用文字，例如：游戏
            submit_obj.profit_min = this.form_data.profit_min;//单条最低利润,单位分
            submit_obj.specify_codenum_onff = this.form_data.specify_codenum_onff;//是否要求指定码号,0:要求；1:不要求
            submit_obj.specify_codenum = this.form_data.specify_codenum;//指定码号  当要求指定码号时生效
            submit_obj.channel_select_style = this.form_data.channel_select_style;//可选通道类别，0:直连；1:准直连；2:三方；3:厂商，复选框(多个使用半角逗号隔开)
            submit_obj.channel_select_type = this.form_data.channel_select_type;//通道选取方式，0:指定通道组；1:自动选通道
            submit_obj.user_channel_ids = this.form_data.user_channel_ids;////客户指定通道组id,多个使用半角逗号隔开
            

            API.UserShuntServlet(submit_obj).then((res) => {
                
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>