<template>
<div>
    <!-- 客户分流比例的修改 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>客户分流比例的修改</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-table :data="tableData_shunt" :show-header="true"  style="width: 100%"  size="mini" >
                    <el-table-column label="分流比例(%)" prop="shunt_rate"  min-width="110"  align="center">
                        <template slot-scope="scope">
                            <template v-if= "scope.$index == 0">
                                {{shunt_rate_main}}
                            </template>
                            <template v-else>
                                <el-input v-model="scope.row.shunt_rate" style="width:80px;" @change="changeRate()" placeholder="请填写分流比例" size="mini" oninput="value=value.replace(/[^\d]/g,'')">
                                </el-input>
                            </template>
                            
                        </template>
                    </el-table-column>
                    <el-table-column label="分流账号" prop="cpid_shunt"  min-width="110"  align="center"> </el-table-column>
                    
                </el-table>
                <el-row type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                    <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                </el-row>
                
                                    
            </el-row>

        </el-row>


    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_cpid:'',//
        prop_item:{},//公司对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
            
                setTimeout(() => {
                    //获取数据
                    this.getData();
                    
                }, 1);
            }

            
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            tableData_shunt:[],//客户分流属性数据

            shunt_rate_main:100,//主账号的比例
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            //this.form_data = this.$options.data().form_data;//单个恢复初始化
            this.tableData_shunt = this.$options.data().tableData_shunt;//单个恢复初始化
       
        },
        //查询
        getData(){
            this.tableData_shunt = this.prop_item.tableData_shunt;
            for(var i=0;i<this.tableData_shunt.length;i++){
                if(i == 0){
                    var item = this.tableData_shunt[i];
                    this.shunt_rate_main = item.shunt_rate;//主账号的比例
                }
            }
            
        },
        //比例改变时执行
        changeRate(){
            var rate_total = 100;
            for(var i=0;i<this.tableData_shunt.length;i++){
                if( i > 0){
                    var item = this.tableData_shunt[i];
                    if(API.isEmtry(item.shunt_rate)){
                        item.shunt_rate = 0;
                    }
                    rate_total = rate_total - parseInt(item.shunt_rate);//主账号的比例
                }
            }
            if(rate_total < 0){
                this.$message.error("超过总分配的比例");
                rate_total = 0;
            }
             
            this.shunt_rate_main = rate_total;
            
        },
       

        //提交
        onSubmit(){
            console.log("提交");
            var jsonArray = [];
            var rate_total = 0;
            for(var i=0;i<this.tableData_shunt.length;i++){
                if( i > 0){
                    var item = this.tableData_shunt[i];
                    if(API.isEmtry(item.shunt_rate)){
                        item.shunt_rate = 0;
                    }
                    rate_total += parseInt(item.shunt_rate);//主账号的比例
                    jsonArray.push(item);
                }
            }
            console.log("rate_total:"+rate_total);
            if(rate_total > 100){
                this.$message.error("超过总分配的比例");
                return;
            }
            
            var submit_obj = {};
            submit_obj.param = "upd_shunt_rate";
            submit_obj.cpid = this.prop_cpid;
            submit_obj.jsonArray = JSON.stringify(jsonArray);

            API.UserShuntServlet(submit_obj).then((res) => {
                
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>